import dayjs from 'dayjs';
import XLSX from 'sheetjs-style-v2';

import {
	AntSelectorDateType,
	ConsistenciaListaErrosItemType,
	DetalhesTransmissaoType,
	InconsistenciaType,
} from '../../../context';

import { corrigirFusoHorario, getClientes } from '../..';

import { ExportadorDePlanilhaProps, IExportadorDePlanilha, TipoInconsistenciaGerarPlanilhaType } from './index.types';

dayjs.locale('pt-BR');

export class ExportadorDePlanlha implements IExportadorDePlanilha {
	private detalhesTransmissao: DetalhesTransmissaoType;
	private nomeUsuario: string;
	private dataReferencia: AntSelectorDateType;
	private nomeCliente: string;
	private nomePatrocinadora: string;

	constructor(props: ExportadorDePlanilhaProps) {
		this.detalhesTransmissao = props.detalhesTransmissao;
		this.nomeUsuario = props.nomeUsuario;
		this.dataReferencia = props.dataReferencia;
		this.nomePatrocinadora = props.nomePatrocinadora;

		const clientes = getClientes();
		const nomeCliente = clientes.find((item) => item.value === props.clienteID)?.label || 'Cliente';
		this.nomeCliente = nomeCliente;

		return this;
	}

	private inicializarAba = () => {
		const { utils } = XLSX;
		const colInfo: XLSX.ColInfo[] = [
			{ wch: 30 },
			{ wch: 35 },
			{ wch: 10 },
			{ wch: 40 },
			{ wch: 17 },
			{ wch: 20 },
			{ wch: 20 },
		];

		const worksheet = utils.aoa_to_sheet([[]]);
		worksheet['!cols'] = colInfo;

		return worksheet;
	};

	private inserirAbaEmPlanilha = (workbook: XLSX.WorkBook, worksheet: XLSX.WorkSheet, nome: string) => {
		const { utils } = XLSX;

		return utils.book_append_sheet(workbook, worksheet, nome);
	};

	private inserirDadosEmAbaExistente = (
		worksheet: XLSX.WorkSheet,
		dados: any[][] | any,
		opts?: XLSX.SheetAOAOpts | XLSX.SheetJSONOpts
	) => {
		const { utils } = XLSX;

		const array = Array.isArray(dados);
		const method = array ? utils.sheet_add_aoa : utils.sheet_add_json;
		return method(worksheet, dados, { cellStyles: true, ...opts });
	};

	private inserirInconsistenciasEmAba = (worksheet: XLSX.WorkSheet, dados: any[], fatal: boolean) => {
		const linhaOrigem = 10; // onde começar a preencher
		const headerStyle = {
			fill: { fgColor: { rgb: '0c1642', patternType: 'solid' } },
			font: { color: { rgb: 'ffffff' } },
			alignment: { horizontal: 'center' },
		};

		const headers = [['Inconsistência', 'Patrocinadora', 'Matrícula', 'Nome', 'CPF', 'Campo', 'Valor']].map((row) => {
			return row.map((v) => ({ v, s: headerStyle }));
		});

		const dadosFiltrados = dados
			.filter((dado) => fatal === dado.fatal)
			.map((dado) => {
				const { inconsistencia, empregador, matricula, nome, CPF, campo, valorInconsistente } = dado;
				return [inconsistencia, empregador, matricula, nome, CPF, campo, valorInconsistente];
			});

		const dadosCompletos = [...headers, ...dadosFiltrados];

		return this.inserirDadosEmAbaExistente(worksheet, dadosCompletos, { origin: linhaOrigem });
	};

	private mapListaInconsistencias = (inconsistencia: InconsistenciaType) => {
		const mapListaErros = (erro: ConsistenciaListaErrosItemType) => {
			return { ...erro, fatal: inconsistencia.erroFatal, inconsistencia: inconsistencia.mensagemErro };
		};
		return inconsistencia.listaErros.map(mapListaErros);
	};

	private validarParametrosGerarPlanilhaInconsistencias = (
		tiposSelecionados: TipoInconsistenciaGerarPlanilhaType[],
		inconsistenciasSelecionadas: string[]
	) => {
		if (tiposSelecionados.length === 0) throw new Error('Necessário especificar os tipos de inconsistência!');
		if (inconsistenciasSelecionadas.length === 0) throw new Error('Necessário selecionar as inconsistências!');
		return;
	};

	// Métodos Públicos!
	public gerarPlanilhaInconsistencias = (
		tiposSelecionados: TipoInconsistenciaGerarPlanilhaType[],
		inconsistenciasSelecionadas: string[]
	) => {
		const { utils } = XLSX;
		const detalhes = this.detalhesTransmissao;

		this.validarParametrosGerarPlanilhaInconsistencias(tiposSelecionados, inconsistenciasSelecionadas);

		const dataImportacao = `${corrigirFusoHorario(detalhes.dataHoraImportacao).toLocaleString()} - ${
			detalhes.dadosUsuario.nomeUsuario
		}`;

		const dataEmissao = `${new Date().toLocaleString()} - ${this.nomeUsuario}`;
		const dataCompetencia = this.dataReferencia?.format('MMMM / YYYY');

		const metadados = {
			'Transmissão de Arquivos': '',
			Cliente: this.nomeCliente,
			Competência: dataCompetencia,
			//Competência: `${dataCompetencia?.getMonth()}/${dataCompetencia?.getFullYear()}`,
			'Arquivo Importação': this.nomePatrocinadora,
			'Identificador do Arquivo': detalhes.arquivoID,
			'Nome do arquivo importado': detalhes.nomeArquivo,
			'Data Importação - Responsável': dataImportacao,
			'Data Emissão - Responsável': dataEmissao,
		};

		const listaInconsistencias = detalhes.inconsistencias;
		const dadosMapeados = listaInconsistencias
			.filter((inconsistencia) => inconsistenciasSelecionadas.includes(inconsistencia.mensagemErro))
			.map(this.mapListaInconsistencias)
			.flat();

		const dadosOrdenados = [...dadosMapeados]
			.sort((a, b) => {
				return a.nome.localeCompare(b.nome);
			})
			.sort((a, b) => {
				return a.campo.localeCompare(b.campo);
			})
			.sort((a, b) => {
				return a.inconsistencia.localeCompare(b.inconsistencia);
			});

		// Inserindo abas
		const workbook = utils.book_new();

		tiposSelecionados.forEach((tipo) => {
			const mergeCells = [
				{ s: { r: 0, c: 0 }, e: { r: 0, c: 1 } }, // Mesclar células A1:B1
			];

			const fatal = tipo === 'Inconsistências Fatais';

			const aba = this.inicializarAba();
			const abaComMetadados = this.inserirDadosEmAbaExistente(aba, Object.entries(metadados), { origin: 0 });
			const abaCompleta = this.inserirInconsistenciasEmAba(abaComMetadados, dadosOrdenados, fatal);
			abaCompleta['!merges'] = mergeCells;
			this.inserirAbaEmPlanilha(workbook, abaCompleta, tipo);
		});

		return workbook;
	};

	public exportarPlanilhaInconsistencias = (workbook: XLSX.WorkBook) => {
		return XLSX.writeFile(workbook, `${this.detalhesTransmissao.nomeArquivo}_Lista de Inconsistências.xlsx`, {
			compression: true,
			cellStyles: true,
		});
	};
}

export * from './index.types';
