import { ArquivoMovimentoIDType, ArquivoStatusIDType, ComponentStatusType, FormatoArquivoIDType } from '../../context';

export const getArquivoStatusTradutorMap = () => {
	const tradutor = new Map<ArquivoStatusIDType, { status: ComponentStatusType; label: string }>();

	// Era 0, agora é 8
	tradutor.set(8, { status: 'info', label: 'Não transmitido' });

	tradutor.set(1, { status: 'info', label: 'Iniciada' });
	tradutor.set(2, { status: 'error', label: 'Fatal' });
	tradutor.set(3, { status: 'success', label: 'Sucesso' });
	tradutor.set(4, { status: 'error', label: 'Importação cancelada' });
	tradutor.set(5, { status: 'success', label: 'Importação processada' });
	tradutor.set(6, { status: 'error', label: 'Erro na importação' });
	tradutor.set(7, { status: 'warning', label: 'Inconsistências' });

	return tradutor;
};

export const getStatusFromArquivoStatusID = (arquivoStatusID: ArquivoStatusIDType) => {
	const tradutor = getArquivoStatusTradutorMap();
	const resultado = tradutor.get(arquivoStatusID);
	return resultado || { status: 'error', label: '-' };
};

export const getArquivoMimeTypeTradutor = () => {
	const tradutor = new Map<FormatoArquivoIDType, { label: string; mimetype: string }>();

	tradutor.set(1, { label: 'Texto', mimetype: '.dat, text/*' });
	tradutor.set(2, { label: 'Texto Delimitado', mimetype: '.dat, text/*' });
	tradutor.set(3, {
		label: 'Excel',
		mimetype: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
	});

	return tradutor;
};

export const getFormatoFromFormatoArquivoID = (arquivoFormato: FormatoArquivoIDType) => {
	const tradutor = getArquivoMimeTypeTradutor();
	const resultado = tradutor.get(arquivoFormato);

	return resultado || { label: 'Arquivo', mimetype: '*' };
};

export const getArquivoMovimentoTradutor = () => {
	const tradutor = new Map<ArquivoMovimentoIDType, { mostrarContribuicoes?: boolean; mostrarRegistros?: boolean }>();

	tradutor.set(1, { mostrarRegistros: true });
	tradutor.set(2, { mostrarContribuicoes: true });
	tradutor.set(3, { mostrarContribuicoes: true, mostrarRegistros: true });

	return tradutor;
};

export const getConfigResumoFromTipoArquivoMovimentoID = (tipoArquivoMovimento: ArquivoMovimentoIDType) => {
	const tradutor = getArquivoMovimentoTradutor();
	const resultado = tradutor.get(tipoArquivoMovimento);

	return resultado || {};
};

export function getKeySorter<T>(key: keyof T) {
	return (curr: T, prev: T) => {
		const current = curr[key];
		const previous = prev[key];

		if (!current) return -1;
		if (!previous) return 1;

		if (previous === current) return 0;

		return current < previous ? 1 : -1;
	};
}

export const corrigirFusoHorario = (data: string) => {
	return new Date(data.slice(0, -1));
};

export const getNumberToRealCurrencyFormatter = () => {
	return Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });
};

export const getClientes = () => {
	return [
		{ label: 'PrevPepsico', value: '5' },
		{ label: 'Rumos', value: '6' },
		{ label: 'CargillPrev', value: '8' },
		{ label: 'PreviHonda', value: '9' },
		{ label: 'ReckittPrev', value: '10' },
		{ label: 'MutuoPrev', value: '11' },
		{ label: 'MSD Prev', value: '12' },
		{ label: 'CP Prev', value: '13' },
		{ label: 'Sarah Prev', value: '14' },
		{ label: 'Gerdau Prev', value: '15' },
		{ label: 'Prevsan', value: '19' },
		{ label: 'Curitiba Prev', value: '20' },
	];
};
