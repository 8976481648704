import { createContext, memo, ReactNode, useCallback, useMemo } from 'react';

import { DefaultSessionStorageContext } from './index.types';

const SessionStorageContext = createContext<DefaultSessionStorageContext>(undefined!);

const SessionStorageContextProvider: React.FC<{ children: ReactNode }> = memo((props) => {
	const setItem = useCallback((key: string, value: string) => {
		sessionStorage.setItem(key, value);
	}, []);

	const getItem = useCallback((key: string) => {
		return sessionStorage.getItem(key);
	}, []);

	const removeItem = useCallback((key: string) => {
		sessionStorage.removeItem(key);
	}, []);

	const context = useMemo(() => {
		return { setItem, getItem, removeItem };
	}, [getItem, setItem, removeItem]);

	return <SessionStorageContext.Provider value={context}>{props.children}</SessionStorageContext.Provider>;
});

export * from './index.types';
export { SessionStorageContext, SessionStorageContextProvider };
