import axios, { AxiosRequestConfig } from 'axios';
import { createContext, memo, useCallback, useContext, useMemo } from 'react';

import { AuthContext } from '../authContext';

import { AntSelectorDateType } from '../index.types';
import {
	DefaultApiContext,
	GetClientesAutorizadosResponseType,
	GetDetalhesResponseType,
	GetHistoricoResponseType,
	GetInfoResponseType,
	GetMesDeProcessamentoResponseType,
	PostArquivoResponseType,
} from './index.types';

const ApiContext = createContext<DefaultApiContext>(undefined!);

const ApiContextProvider: React.FC<{ children: React.ReactNode }> = memo(({ children }) => {
	const authContext = useContext(AuthContext);

	const apiUrl = useMemo(() => process.env.REACT_APP_API_URL ?? 'http://localhost:8080', []);

	const api = useMemo(() => {
		const headers = { Authorization: `Bearer ${authContext.accessToken}` };
		return axios.create({ baseURL: apiUrl, headers });
	}, [apiUrl, authContext.accessToken]);

	// GET /api/v1/info/data
	// recupera o mês de processamento no qual o sistema está trabalhando
	const getMesDeProcessamento = useCallback(
		async (clienteID: string) => {
			const params = { clienteID };
			return api.get<GetMesDeProcessamentoResponseType>('/api/v1/info/data', { params });
		},
		[api]
	);

	// GET /api/v1/info - query: clienteID, dataReferencia
	// clienteID se refere ao cliente, então preciso saber qual o cliente desse usuário
	const getArquivos = useCallback(
		async (clienteID: string, dataReferencia: AntSelectorDateType) => {
			const params = { clienteID, dataReferencia: dataReferencia?.toISOString().slice(0, 10) };
			return api.get<GetInfoResponseType>('/api/v1/info', { params });
		},
		[api]
	);

	// GET /api/v1/info/clientes
	// recupera o mês de processamento no qual o sistema está trabalhando
	const getClientesAutorizados = useCallback(async () => {
		return api.get<GetClientesAutorizadosResponseType[]>('/api/v1/info/clientes');
	}, [api]);

	// POST /api/v1/tradutor
	const postArquivo = useCallback(
		async (clienteID: string, data: FormData, config?: AxiosRequestConfig<FormData>) => {
			if (!authContext.user?.sub) throw new Error('Usuário sem ID!');
			data.append('usuarioID', authContext.user?.sub);

			// ROTA PRA TESTE!
			// console.info('ApiContext: Postando pra /tradutorTeste!');
			// return api.post<PostArquivoResponseType>(`/api/v1/tradutorTeste?clienteID=${clienteID}`, data, config);

			// ROTA PRA PRODUÇÃO!
			// throw new Error('Chamando API de produção! Cuidado!');
			return api.post<PostArquivoResponseType>(`/api/v1/tradutor?clienteID=${clienteID}`, data, config);
		},
		[api, authContext.user]
	);

	const getHistorico = useCallback(
		async (clienteID: string, dataReferencia: AntSelectorDateType, tipoArquivoImportacaoID: number) => {
			const params = { clienteID, dataReferencia: dataReferencia?.toISOString().slice(0, 10), tipoArquivoImportacaoID };

			return api.get<GetHistoricoResponseType>('/api/v1/info/historico', { params });
		},
		[api]
	);

	const getDetalhes = useCallback(
		async (clienteID: string, arquivoImportacaoID: number) => {
			const params = { clienteID, arquivoImportacaoID };

			return api.get<GetDetalhesResponseType>('/api/v1/info/historico/detalhes', { params });
		},
		[api]
	);

	const deleteArquivoImportado = useCallback(
		async (clienteID: string, arquivoImportacaoID: number) => {
			const params = { clienteID, arquivoImportacaoID };

			return api.delete<void>(`/api/v1/arquivo`, { params });
		},
		[api]
	);

	const context = useMemo(() => {
		return {
			getArquivos,
			postArquivo,
			getMesDeProcessamento,
			getHistorico,
			getDetalhes,
			deleteArquivoImportado,
			getClientesAutorizados,
		};
	}, [
		getArquivos,
		postArquivo,
		getMesDeProcessamento,
		getHistorico,
		getDetalhes,
		deleteArquivoImportado,
		getClientesAutorizados,
	]);

	return <ApiContext.Provider value={context}>{children}</ApiContext.Provider>;
});

export * from './index.types';
export { ApiContext, ApiContextProvider };
