import { message } from 'antd';
import { createContext, useCallback, useMemo } from 'react';

import { DefaultMessageContext } from './index.types';

const MessageContext = createContext<DefaultMessageContext>(undefined!);

const MessageContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
	const [messageApi, contextHolder] = message.useMessage();

	const messageLoading = useCallback(
		(key: string, content: string = 'Carregando...') => {
			messageApi.open({ key, content, type: 'loading', duration: 10 });
		},
		[messageApi]
	);

	const messageSuccess = useCallback(
		(key: string, content: string = 'Sucesso!') => {
			messageApi.open({ key, type: 'success', content });
		},
		[messageApi]
	);

	const messageError = useCallback(
		(key: string, content: string = 'Um erro ocorreu!') => {
			messageApi.open({ key, type: 'error', content });
		},
		[messageApi]
	);

	const messageWarning = useCallback(
		(key: string, content: string = 'A aplicação precisa de atenção!') => {
			messageApi.open({ key, type: 'warning', content });
		},
		[messageApi]
	);

	const context = useMemo<DefaultMessageContext>(() => {
		return { messageLoading, messageSuccess, messageError, messageWarning };
	}, [messageLoading, messageSuccess, messageError, messageWarning]);
	return (
		<MessageContext.Provider value={context}>
			{contextHolder}
			{children}
		</MessageContext.Provider>
	);
};

export * from './index.types';
export { MessageContext, MessageContextProvider };
