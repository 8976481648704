import { Modal } from 'antd';
import Keycloak from 'keycloak-js';
import { createContext, memo, useCallback, useEffect, useMemo, useState } from 'react';

import { useGlobalConfigFile } from '../../hooks';

import { AuthContextProviderReducerType, AuthProfileType, AuthUserType, DefaultAuthContext } from './index.types';

const AuthContext = createContext<DefaultAuthContext>(undefined!);

export const authContextProviderReducer = (
	prev: AuthContextProviderReducerType,
	next: Partial<AuthContextProviderReducerType>
) => {
	return { ...prev, ...next };
};

const AuthContextProvider: React.FC<{ children: React.ReactNode }> = memo(({ children }) => {
	const { keycloakConfig } = useGlobalConfigFile();

	const [isLoading, setIsLoading] = useState(true);
	const [user, setUser] = useState<AuthUserType>();
	const [profile, setProfile] = useState<AuthProfileType>();
	const [isModalOpen, setIsModalOpen] = useState(false);

	const [modal, contextHolder] = Modal.useModal();

	const keycloak = useMemo(() => new Keycloak(keycloakConfig), [keycloakConfig]);

	// Inicializa o keycloak
	useEffect(() => {
		if (!isLoading) return;

		try {
			keycloak
				.init({ onLoad: 'login-required' })
				.then(() => setIsLoading(false))
				.catch((err) => console.error(err));
		} catch (err: any) {
			console.error(`AuthContext: ${err?.message}`);
		}
	}, [keycloak, isLoading]);

	// Carrega info do usuário
	useEffect(() => {
		if (isLoading) return;

		keycloak.loadUserInfo().then((info) => {
			setUser(info as AuthUserType); // Forçando o tipo
		});
	}, [keycloak, isLoading]);

	// Carrega perfil do usuário
	useEffect(() => {
		if (isLoading) return;

		keycloak.loadUserProfile().then((profile) => setProfile(profile));
	}, [keycloak, isLoading]);

	const isAuthenticated = useMemo(() => keycloak.authenticated, [keycloak.authenticated]);
	const idToken = useMemo(() => keycloak.idToken, [keycloak.idToken]);
	const accessToken = useMemo(() => keycloak.token, [keycloak.token]);
	const logout = useCallback(() => keycloak.logout(), [keycloak]);

	const checkSessionExpiration = useCallback(() => {
		const isExpired = keycloak.isTokenExpired();

		if (isExpired) {
			console.error('AuthContext: Sessão expirada!');

			setIsModalOpen((prev) => {
				// Só abre um novo caso não esteja aberto!
				if (!!prev) return prev;

				const modalProps = {
					title: 'Sua sessão expirou!',
					content: 'Por favor, atualize a página para continuar.',
					okText: 'Atualizar',
					keyboard: false,
					onOk: () => keycloak.login(),
				};

				modal.error(modalProps);
				return true;
			});
		}

		return isExpired;
	}, [keycloak, modal]);

	// Checa a sessão
	useEffect(() => {
		if (!user) return;
		if (isModalOpen) return;

		const minutos = 0.5;
		const interval = setInterval(checkSessionExpiration, minutos * 60 * 1000);

		return () => clearInterval(interval);
	}, [user, isModalOpen, checkSessionExpiration]);

	const context = useMemo(() => {
		return { user, profile, isAuthenticated, isLoading, idToken, accessToken, logout, checkSessionExpiration };
	}, [user, profile, isAuthenticated, isLoading, idToken, accessToken, logout, checkSessionExpiration]);

	return (
		<AuthContext.Provider value={context}>
			{contextHolder}
			{children}
		</AuthContext.Provider>
	);
});

export * from './index.types';
export { AuthContext, AuthContextProvider };
