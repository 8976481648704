import { createContext, memo, ReactNode, useCallback, useMemo } from 'react';

import { DefaultLocalStorageContext } from './index.types';

const LocalStorageContext = createContext<DefaultLocalStorageContext>(undefined!);

const LocalStorageContextProvider: React.FC<{ children: ReactNode }> = memo((props) => {
	const setItem = useCallback((key: string, value: string) => {
		localStorage.setItem(key, value);
	}, []);

	const getItem = useCallback((key: string) => {
		return localStorage.getItem(key);
	}, []);

	const removeItem = useCallback((key: string) => {
		localStorage.removeItem(key);
	}, []);

	const context = useMemo(() => {
		return { setItem, getItem, removeItem };
	}, [setItem, getItem, removeItem]);

	return <LocalStorageContext.Provider value={context}>{props.children}</LocalStorageContext.Provider>;
});

export * from './index.types';
export { LocalStorageContext, LocalStorageContextProvider };
