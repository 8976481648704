import { LogoutOutlined } from '@ant-design/icons';
import { Avatar, Card, Form, Space } from 'antd';
import { Button } from 'design-system';
import { memo, useContext } from 'react';

import { AuthContext } from '../../../../store';

import styles from './index.module.scss';

const AvatarPopoverContent = memo(() => {
	const { logout, user } = useContext(AuthContext);

	return (
		<Space direction='vertical'>
			<Card className={styles.card} bodyStyle={{ padding: 0 }} id='avatar-popover-content-card'>
				<Space direction='vertical' className='w-100'>
					<Space>
						<Form rootClassName={styles.avatarContainer}>
							<Avatar className={styles.mainUserAvatar} size='large'>
								{user?.name.slice(0, 2).toLocaleUpperCase()}
							</Avatar>
						</Form>

						<Space direction='vertical' size='small' className={styles.userInfoSpace}>
							<span className={styles.userName}>{user?.name ?? 'Usuário'}</span>
							<span className={styles.userEmail}>{user?.email ?? 'usuario@email.com'}</span>
						</Space>
					</Space>
				</Space>
			</Card>
			<Button type='text' size='small' icon={<LogoutOutlined />} className='w-100' onClick={() => logout()}>
				Sair
			</Button>
		</Space>
	);
});

export default AvatarPopoverContent;
