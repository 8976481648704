import { useCallback, useMemo } from 'react';

import { GlobalConfig } from '../context';

export const useGlobalConfigFile = () => {
	const validateKeycloakConfig = useCallback((config: any) => {
		return config?.url && config?.realm && config?.clientId;
	}, []);

	const validateConfig = useCallback(
		(config: any) => {
			if (!config.keycloakConfig) throw new Error('keycloakConfig ausente no arquivo de configuração!');

			return validateKeycloakConfig(config.keycloakConfig);
		},
		[validateKeycloakConfig]
	);

	const globalConfig = useMemo<GlobalConfig>(() => {
		// Variável definida em '/public/config.js'
		// @ts-ignore
		if (!validateConfig(publicAppConfig)) throw new Error('Arquivo de configuração inválido!');

		// @ts-ignore
		return publicAppConfig;
	}, [validateConfig]);

	return globalConfig;
};
